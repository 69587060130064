const MONTHLY_DATA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

export default {
  availability: {
    data: [],
    startDate: null,
    endDate: null,
    school: {},
    primaryContact: {},
    memberContacts: [],
    availabilityPrecedence: null,
    adding: false,
    loading: false,
    bulkCreate: {
      loading: false,
    },
  },
  dashboardOld: {
    weeklyLoading: false,
    weeklyUpcomingSchoolVisits: 0,
    weeklyChangesToSchoolVisits: 0,
    weeklyUpcomingSessions: 0,
    eventsLoading: false,
    events: [],
    connectionsLoading: false,
    connectionsNewTotal: 0,
    connectionsNewStudents: 0,
    connectionsNewGuardians: 0,
    connectionsNewCounselors: 0,
    connectionsUpdatedTotal: 0,
    connectionsUpdatedStudents: 0,
    connectionsUpdatedGuardians: 0,
    connectionsUpdatedCounselors: 0,
  },
  dashboard: {
    calendlyEvents: [],
    loading: false,
    onboarding: {
      isOnboardingDone: true,
      visits_feedback_pending: false,
      has_leads_available: false,
      step: 1,
    },
    selectedFilterBadges: [],
    regionFilter: {
      loading: false,
      regions: [
        // {
        //   id: 11,
        //   name: "North America",
        //   countries: [
        //     {
        //       id: 1,
        //       name: "Singapore",
        //     },
        //   ],
        // },
      ],
      regionsState: [],
      filteredRegionState: [],
      selectedCountryIds: [],
      selected: [
        { regionId: 11, countryIds: [1, 2, 3, 4] },
        { regionId: 12, countryIds: [1, 2, 3, 4] },
      ],
    },
    trendChart: {
      loading: false,
      connectedCount: 0,
      counsellorsCount: 0,
      guardianCount: 0,
      internationalStudentsCount: 0,
      currentYearMonthlyData: MONTHLY_DATA,
      lastYearMonthlyData: MONTHLY_DATA,
      currentYearCumulativeData: MONTHLY_DATA,
      lastYearCumulativeData: MONTHLY_DATA,
    },
    addedTrendChart: {
      loading: false,
      addedToListCount: 0,
      internationalStudentsCount: 0,
      currentYearMonthlyData: MONTHLY_DATA,
      lastYearMonthlyData: MONTHLY_DATA,
      currentYearCumulativeData: MONTHLY_DATA,
      lastYearCumulativeData: MONTHLY_DATA,
    },
    applicantsTrendChart: {
      loading: false,
      applyingCount: 0,
      internationalCount: 0,
      submittedCount: 0,
      directAppCount: 0,
      currentYearMonthlyData: MONTHLY_DATA,
      lastYearMonthlyData: MONTHLY_DATA,
      currentYearCumulativeData: MONTHLY_DATA,
      lastYearCumulativeData: MONTHLY_DATA,
    },
    insightsTrendChart: {
      loading: false,
      applyingListMonthlyData: MONTHLY_DATA,
      applyingListCumulativeData: MONTHLY_DATA,
      longListMonthlyData: MONTHLY_DATA,
      longListCumulativeData: MONTHLY_DATA,
      shortListMonthlyData: MONTHLY_DATA,
      shortListCumulativeData: MONTHLY_DATA,
    },
    applicationFunnel: {
      loading: false,
      tab: "connected",
      connected: "-",
      addedToList: "-",
      applying: "-",
      offeredToJoin: "-",
    },
    trendlineWidget: {
      loading: false,
      chartType: "Cumulative", // or "Monthly"
      trendSeries: [
        // { month: May, year: 2021, total_connections: 6 },
        // { month: Jun, year: 2021, total_connections: 27 },
      ],
    },
    newConnections: {
      loading: false,
      connections: [],
      total: 0,
      free: 0,
      paid: 0,
      lastViewed: null,
    },
    addedToListNewActivities: {
      loading: false,
      connections: [],
      total: 0,
      free: 0,
      paid: 0,
    },
    applicantsNewActivities: {
      loading: false,
      connections: [],
      total: 0,
      free: 0,
      paid: 0,
    },
    countryWiseOverview: {
      loading: false,
      totalConnections: 0,
      countryWiseConnections: [
        // {
        //   country: "Singapore",
        //   total: 5000
        // }
      ],
    },
    splitsAndDimensions: {
      loading: false,
      tab: "connectionsInsights",
      connectionsInsights: {
        loading: false,
        addedToList: 0,
        events: 0,
        profile: 0,
        visits: 0,
        total: 0,
      },
      listing: {
        loading: false,
        applyingList: 0,
        shortList: 0,
        longList: 0,
        total: 0,
      },
      events: {
        loading: false,
        total: 0,
        connections: [
          // {
          //   university: "abc",
          //   total: 9000
          // }
        ],
      },
      profile: {
        loading: false,
        total: 0,
        unique: 0,
        connections: 0,
      },
      visits: {
        loading: false,
        total: 0,
        connections: [],
      },
      addedToListInsights: {
        chartType: "Monthly", // or Cummulative
        trendSeries: [
          // { month: May, year: 2021, applyingList: 6, longList: 9, shortList: 9 },
          // { month: May, year: 2021, applyingList: 6, longList: 9, shortList: 9 },
        ],
      },

      applicantsInsights: {
        country: 7, // ?
        recommendedSchoolsToVisit: [
          // ?
          // { school: "Singapore American School", country: "Singapore", applicants: 800 },
          // { school: "Singapore American School", country: "Singapore", applicants: 800 },
        ],
      },
    },
    recommendedSchoolsToVisit: {
      loading: false,
      loadingPast: false,
      schools: [],
      schoolsPast: [],
    },
    addedToListRecommendedSchoolsToVisit: {
      loading: false,
      schools: [],
    },
    applicantsRecommendedSchoolsToVisit: {
      loading: false,
      schools: [],
    },
    events: {
      loading: false,
      events: [],
    },
  },
  directApply: {
    data: [],
    downloadUrl: null,
    loading: false,
    downloading: null,
    selected: null,
    selectedIndex: -1,
    loadingSelected: false,
    matched: 0,
    total: 0,
    pastTotal: 0,
    programs: [],
    countries: [],
    courses: [],
    results: [],
    status: [],
    feeWaived: [],
    schools: [],
    activities: [],
    programsFilterUniversities: [],
    programsFilterStatuses: [],
    programsFilterRounds: [],
    isLoadingActivities: false,
    applicationResults: [],
    applicationStatuses: [],
    params: {
      page_no: 1,
    },
    feeStatuses: [],
    ucasChoices: [],
  },
  directApplyListState: {
    searchText: "",
    sortBy: { column: "submitted_at", direction: "desc" },
    tab: "upcoming",
    filters: {},
  },
  directApplyPrograms: {
    data: [],
    matched: 0,
    total: 0,
    programDownloadUrl: null,
  },
  directApplyProgram: {
    program: null,
    isLoadingProgram: false,
    programLoadFailed: false,
    isUpdating: false,
    isDeleting: false,
    isCreatingCopy: false,
    isSavingDraft: false,
    isActivating: false,
    isDeactivating: false,
    updateSuccess: false,
    deleteSuccess: false,
    createProgramCopySuccess: false,
    saveDraftSuccess: false,
    activateSuccess: false,
    deactivateSuccess: false,
    programTypes: [],
    programTypeContent: null,
    currencies: [],
    universities: [],
    brochures: [],
    documentTypes: [],
    banners: [],
    intakeRounds: [],
    isFetchingProgramTypes: false,
    isFetchingProgramTypeContent: false,
    isFetchingCurrencies: false,
    isFetchingUniversities: false,
    isFetchingBrochures: false,
    isFetchingDocumentTypes: false,
    isFetchingBanners: false,
    isFetchingIntakeRounds: false,
    createProgramNewSuccess: false,
    isCreatingNew: false,
    isDraftClicked: false,
  },
  events: {
    data: [],
    loading: false,
    downloadUrl: null,
    downloading: null,
    selected: null,
    loadingSelected: false,
    rescheduling: false,
    cancelling: false,
    confirming: false,
    adding: false,
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },
    total_without_group: 0,
  },
  misc: {
    admins: [],
    countries: [],
    eventsCountries: [],
    teamCountries: [],
    timezones: [],
    regions: [],
    schoolSizes: [],
    members: [],
    eventModificationReasons: [],
    managementTools: [],
    isCalendarExpireMessageVisible: false,
    loadingAdmins: false,
    loadingCountries: false,
    loadingTimezones: false,
    loadingRegions: false,
    loadingSchoolSizes: false,
    loadingMembers: false,
    loadingManagementTools: false,
    loadingEventModificationReasons: false,
    paymentInfo: null,
    loading: false,
    isSendingSlackNotifications: false,
    sendSlackNotificationSuccess: false,
    hasPageLoadTransactionEnded: false,
  },
  notifications: {
    data: [],
    loading: false,
    total: 0,
    num_read: 0,
    num_unread: 0,
    page_no: 1,
    unread_badge_count: 0,
  },
  pageNotifications: {
    data: [],
    loading: false,
    total: 0,
    filter_count: 0,
    no_notification_found: false,
    no_search_results: false,
    page_no: 1,
    is_read: false,
  },
  partner: {
    data: null,
    syncedCalendars: [],
    availableCalendars: [],
    loadingPartner: false,
    updatingPartner: false,
    updatingTimezone: false,
  },
  schoolAppointment: {
    availabilities: {},
    selectedDate: null,
    selectedSlot: null,
    schoolId: null,
    school: null,
    loadingAvailabilities: false,
    loadingMeeting: false,
    meetingUrl: "",
    meetingInstructions: "",
    zoomConnectUrl: "",
  },
  schools: {
    data: [],
    loading: false,
    selected: {
      availabilities: {},
      partner: null,
    },
    loadingSelected: false,
    matched: 0,
    total: 0,
    is_slot_status_visible: true,
    params: {
      page_no: 1,
    },

    pending_feedbacks: {
      total_feedbacks: 0,
      events: [],
      loading: false,
      addFeedbackloading: false,
    },
  },
  sessions: {
    list: [],
    session: null,
    isFetchingList: false,
    listFetchSuccess: false,
    isFetchingSession: false,
    fetchSessionSuccess: false,
    fetchSessionFailed: false,
    isUpdatingSession: false,
    updateSessionSuccess: false,
    updateSessionFailed: false,
  },
  user: {
    authToken: null,
    data: null,
    showOnboarding: false,
    loading: false,
    updatingPassword: false,
    validatedUserPartnerType: null,
    updatingPrivacyPolicyAcceptance: false,
    ssoUrl: null,
    isWebinarLogout: false,
    locale: "en-US",
    settingsTabIndex: 0,
    zoom: {
      authUrl: null,
      account: null,
      updatingZoomDetails: false,
      isConnected: false,
    },
    sisense: {
      url: null,
      loading: false,
      dashboards: [],
    },
    metabase: {
      url: null,
      loading: false,
      dashboards: [],
    },
  },
  team: {
    data: [],
    downloadUrl: null,
    loading: false,
    downloading: null,
    creatingTeamMember: false,
    updatingTeamMember: false,
    performingBulkAction: false,
    selected: null,
    loadingSelected: false,
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },
  },
  webinar: {
    webinarCreatePartner: {},
    categorySearchParams: {},
    categories: [],
    data: [],
    trending: [],
    sessions: [],
    mySessions: {},
    eventProfile: {},
    loading: false,
    selected: null,
    loadingSelected: false,
    filters: [],
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },
  },
  connections: {
    data: [],
    downloadUrl: null,
    loading: false,
    downloading: null,
    total: 0,
    matched: 0,
    is_locked: false,
    loadingSelected: false,
    webinars: [],
    countries: [],
    citizenship: [],
    graduationYear: [],
    params: {
      page_no: 1,
    },
    selectedConnectionDetails: {},
    statuses: [],
    activities: [],
    sources: [],
    schoolStates: [],
    schoolNames: [],
    schoolCity: [],
    academicInterest: [],
    courseInterest: [],
    eventTitle: [],
    selectedActivities: {
      engagements: [],
    },
    totalActivities: 0,
    activitiesParams: {
      page_no: 1,
    },
    loadingConnection: false,
    loadingEngagement: false,
    showBanner: true,
    connectionsCount: 0,
    counselorConnectionsCount: 0,
    guardianConnectionsCount: 0,
    studentConnectionsCount: 0,
  },
  loadingState: {
    registeringSessionIds: [],
  },
  onboarding: {
    loadingOnboardingSeedData: false,
    onboardingSeedData: {},
    loadingOnboardingData: false,
    onboardingData: {},
    peers: [],
    peer_list: [],
    onboardingFirstSteps: {
      completed_steps_count: 0,
    },
  },
  configuration: {
    files: [],
    download_format_mapping: [],
    mapped_columns: [],
    isMappingUpdateRunning: false,
  },
};
