/* eslint-disable camelcase */
import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  getConfigurationFiles,
  getDownloadFormatMapping,
  saveDownloadFormatMapping,
  getMappedColumns,
} from "src/web-services";
import {
  ConfigurationCreators,
  ConfigurationTypes,
} from "../actions";

const authTokenSelector = state => state.user.authToken;

function* requestGetConfigurationFiles() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getConfigurationFiles, authToken);
    yield put(
      ConfigurationCreators.getConfigurationFilesSuccess(
        response.data.files,
      ),
    );
  } catch (error) {
    yield put(ConfigurationCreators.getConfigurationFilesFailure());
  }
}

function* requestGetDownloadFormatFileMapping() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getDownloadFormatMapping, authToken);

    yield put(
      ConfigurationCreators.getDownloadFormatFileMappingSuccess(
        response.data.configuration_mappings,
      ),
    );
  } catch (error) {
    yield put(
      ConfigurationCreators.getDownloadFormatFileMappingFailure(),
    );
  }
}

function* requestSaveDownloadFormatFileMapping(action) {
  try {
    const { mapping_data } = action;
    const authToken = yield select(authTokenSelector);
    yield call(saveDownloadFormatMapping, authToken, mapping_data);

    yield put(
      ConfigurationCreators.saveDownloadFormatFileMappingSuccess(),
    );
  } catch (error) {
    yield put(
      ConfigurationCreators.saveDownloadFormatFileMappingFailure(),
    );
  }
}

function* requestGetMappedColumns() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getMappedColumns, authToken);
    console.log("response", response);
    yield put(
      ConfigurationCreators.getMappedColumnsSuccess(
        response.data.attributes,
      ),
    );
  } catch (error) {
    yield put(ConfigurationCreators.getMappedColumnsFailure());
  }
}

export function* watchConfigurationRequests() {
  yield takeEvery(
    ConfigurationTypes.GET_CONFIGURATION_FILES_REQUEST,
    requestGetConfigurationFiles,
  );
  yield takeEvery(
    ConfigurationTypes.GET_DOWNLOAD_FORMAT_FILE_MAPPING_REQUEST,
    requestGetDownloadFormatFileMapping,
  );
  yield takeEvery(
    ConfigurationTypes.SAVE_DOWNLOAD_FORMAT_FILE_MAPPING_REQUEST,
    requestSaveDownloadFormatFileMapping,
  );
  yield takeEvery(
    ConfigurationTypes.GET_MAPPED_COLUMNS_REQUEST,
    requestGetMappedColumns,
  );
}
